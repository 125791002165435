body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: myFirstFont;
  src: url("./../assets/fonts/Avenir-Regular.ttf");
}

body {
  font-family: myFirstFont;
}
@tailwind base;

@tailwind components;

@tailwind utilities;

.sideIcons {
  @apply py-3 w-full flex items-center  relative;
}
.disabled-input {
  @apply bg-white text-gray-700 text-sm py-3 px-3 w-full rounded-lg border cursor-not-allowed;
}
.disabled-input:focus {
  @apply outline-none;
}

.enabled-input {
  @apply bg-white text-gray-700 text-base py-3 px-3 w-full rounded-lg border;
}
.enabled-input:focus {
  @apply .outline-none;
}
.icon-input {
  padding-left: 2.5rem;
}
.multi-line-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 120px;
}
.containers:hover input ~ .checkmark {
  border-color: #1e37f8;
}

/* When the checkbox is checked, add a blue background */
.containers input:checked ~ .checkmark {
  border-color: #1e37f8;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containers input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containers .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #1e37f8;
  border-width: 0 2px 2px 0;
  transform: rotate(43deg);
}
.p-05 {
  padding: 0.15rem;
}

@media (min-width: 768px) {
  .md-mw-20 {
    max-width: 7rem;
  }
}
@media (max-width: 767px) {
  .mw-16 {
    max-width: 5rem;
  }
}
.tooltip .tooltiptext {
  width: fit-content;
  left: 105%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #1e37f8 transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltip2 .tooltiptext {
  bottom: 140%;
  /* right: -50%; */
  width: 8.2rem;
  left: -30%;
}

.tooltip2 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.w-33 {
  width: 60px;
}
.border-blue-710 {
  border-color: #1e37f8;
}
.border-blue-720 {
  border-color: #0851b2;
}
.text-blue-710 {
  color: #1e37f8;
}
.text-blue-720 {
  color: #0851b2;
}
.bg-yellow-710 {
  background-color: #f8de1e;
}
.text-blue-710hover:hover {
  color: #1e37f8;
}
.border-blue-710focus:focus {
  border: 1px solid #1e37f8;
}

.text-blue-410 {
  color: #00a5ff;
}
.text-gray-110 {
  color: #3d4455;
}

.bg-blue-710 {
  background-color: #1e37f8;
}

.bg-blue-710hover:hover {
  background-color: #1e37f8;
}
.bg-blue-720 {
  background-color: #103d8e;
}

.bg-gray-110 {
  background-color: #f5f6f9;
}

.bg-gray-120 {
  background-color: #f4f5f8;
}

.bg-gray-130 {
  background-color: #e5e9ed;
}

.bg-green-710 {
  background-color: #00bf84;
}

.text-green-710 {
  color: #00bf84;
}
.text-green-710-hover:hover {
  color: #00bf84;
}
.border-green-710 {
  border-color: #00bf84;
}
.border-gray-110 {
  border-color: #f5f6f9;
}

.rounded-4 {
  border-radius: 4px;
}
.py-1half {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.rounded-8 {
  border-radius: 8px;
}
.text-10 {
  font-size: 10px;
}

.h-full-calc {
  min-height: calc(100vh - 16.25rem);
}
.h-full-list {
  height: calc(100vh - 18rem) !important;
}
.py-35 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.h-full-calc2 {
  min-height: calc(100vh - 11rem);
}
.h-full-calc5 {
  min-height: calc(100vh - 7rem);
}
.h-full-calc-3 {
  min-height: calc(100vh - 4rem);
}
.border-6 {
  border-width: 6px;
}
.bg-red-710 {
  background-color: #fc615f;
}
.border-red-710 {
  border-color: #fc615f;
}
.text-red-710-hover:hover {
  color: #fc615f;
}
.text-red-710 {
  color: #fc615f;
}
.w-14 {
  width: 3.5rem;
}
.h-calc {
  min-height: calc(100vh - 5rem);
}

.h-85 {
  height: 85%;
}

.h-15 {
  height: 15%;
}
.w-screen-1525 {
  width: 100%;
}
.wh-30 {
  width: 7rem;
  height: 7rem;
}
.h-80 {
  height: 20rem;
}
.h-full-calc-5 {
  min-height: calc(100vh - 5rem);
}
.h-full-calc-4 {
  min-height: calc(100vh - 4rem);
}
.w-22 {
  width: 5.9rem;
}
.w-60 {
  width: 15rem;
}
.bg-blue-810 {
  background: #354aa5;
}
.pl-50 {
  padding-left: 13rem;
}

@media (min-width: 768px) {
  .pl-515 {
    padding-left: 5.15rem;
  }
  .md-bg-blue-710 {
    background-color: #1e37f8 !important;
  }
}

@media (max-width: 769px) {
  .h-15 {
    height: 4rem !important;
  }
}
@media (min-width: 769px) {
  .sideIcons:hover .activeBorder {
    display: block;
  }
  .activeClass .svgColorfill {
    /* fill: #1e37f8; */
  }
  .activeClass .svgColorstroke {
    /* stroke: #1e37f8; */
  }

  .sideIcons:hover .svgColorfill {
    /* fill: #1e37f8; */
  }

  .sideIcons:hover .svgColorstroke {
    /* stroke: #1e37f8; */
  }
}
.activeClass {
  border-left: 3px solid #FD2A28 !important;
  background-color: darkgrey;
}
.sideIcons:hover {
  border-left: 3px solid #FD2A28 !important;
  background-color: darkgrey;
}
.sideIcons {
  border-left: 3px solid darkgrey;
}
@media (max-width: 768px) {
  .activeClass {
    background-color: darkgrey;
  }
  .activeClass .svgColorfill {
    fill: #fff;
  }
  .activeClass .svgColorstroke {
    stroke: #fff !important;
  }
  .activeClass .mobTip {
    color: #fff !important;
  }
  .topSearch {
    background-color: white;
  }
  .topSearch::placeholder {
    color: transparent !important;
  }
}

.activeClass .svgColorstroke {
  /* stroke: #1e37f8; */
}
@media (min-width: 769px) {
  .activeClass .activeBorder {
    display: block;
  }
  .activeClass .svgColorstroke {
    /* stroke: #1e37f8; */
  }
}

.pl-36 {
  padding-left: 9rem;
}
@media (min-width: 1025px) {
  .lg-min-w-6 {
    width: 51% !important;
  }
  .h-screen-5rem {
    height: calc(100vh - 5.05rem);
  }
  .h-screen-6 {
    min-height: calc(100vh - 5.25rem) !important;
  }
  .w-screen-1525 {
    width: calc(100vw - 17.25rem);
  }
  .ml-1525 {
    margin-left: 11.75rem;
  }
}
.h-screen-6 {
  min-height: calc(100vh - 9rem);
}
.w-fit {
  width: fit-content;
}
.pb-8imp {
  padding-bottom: 1rem !important;
}
.w-101 {
  max-width: 40rem;
}
@media (min-width: 768px) {
  .md-min-w-6 {
    width: 51% !important;
  }
  .mt-525 {
    margin-top: 5.25rem;
  }
  .w-525 {
    width: 5.25rem !important;
    display: flex !important;
  }
  #sideInner {
    display: flex !important;
    width: 100% !important;
  }
}
.w-525 {
  width: 0%;
}
.h-525 {
  height: 5.25rem;
}
.pt-35 {
  padding-top: 0.35rem;
}
.top-7 {
  top: 7rem;
}
.w-fit {
  width: fit-content;
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e37f8;
  border-radius: 14px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #122beb;
}

.popup-overlay {
  width: 100vw;
  height: 100vh;
}
.popup-overlay::after {
  content: "";
  background-color: #000;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.popup-content {
  background-color: #fff;
  opacity: 1;
  z-index: 1;
  border-radius: 4px;
}
button {
  outline: none !important;
}
@media (min-width: 768px) {
  .w-100 {
    width: 25rem;
  }
}

.f-28 {
  font-size: 28px;
}
.f-15 {
  font-size: 15px;
}
.f-53 {
  font-size: 53px;
}
.border-gray-210 {
  border-color: #203595;
}

.multi-select .dropdown-content label{
    display: flex !important;
    justify-content: flex-start !important;
    padding: 7px 15px !important;
}

.multi-select .dropdown-content ul{
  margin-bottom: 7%;
}